body {
  background: url('Images/background1.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
a, a:hover {
    color: white;
    text-decoration: none;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

ul.sns_icons {
    display: inline;
    padding-left: 0;
}
ul.sns_icons > li {
    display: inline;
    list-style: none;
    padding-right: 5px;
}
ul.sns_icons > li img {
    width: 30px;
    height: 30px;
}
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
